import * as React from 'react';
import styled from '@emotion/styled';
import * as currencyJs from 'currency.js';
import { useMediaQuery } from 'react-responsive';
import { ReactSEOMetaTags } from 'react-seo-meta-tags';
import { useMutation } from 'react-query';
import { useParams, useHistory, Link } from 'react-router-dom';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { TwitterShareButton, FacebookShareButton } from 'react-share';
import {
  Pane,
  Text,
  Heading,
  ListItem,
  Paragraph,
  UnorderedList,
  toaster,
  minorScale,
  majorScale,
  Dialog,
} from 'evergreen-ui';
import queryString from 'query-string';
import Countdown, { zeroPad } from 'react-countdown';
import { Helmet } from 'react-helmet';
import { isEmpty, get } from 'lodash';

import Btn from '../../components/btn/btn';
import { isAuthVar, authUserVar } from '../../helpers/auth';
import { getErrorMessage } from '../../helpers';
import Footer from '../../components/footer/footer';
import Container from '../../components/container/container';
import AuthModal from '../../features/auth/auth-modal/auth-modal';
import {
  VIEWPORT_BREAKPOINTS,
  APPROVAL_TYPE,
  NOTIFICATION_TYPE,
} from '../../helpers/enums';
import EventExpired from '../../components/event-expired/event-expired';
import FavoriteEvent from '../../features/favorite-event/favorite-event';
// import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import PhotoLightbox from '../../components/photo-lightbox/photo-lightbox';
import EventDetailsHeader from './event-details-header/event-details-header';
import EventDetailsLoader from '../../components/loaders/event-details-loader';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import WithoutloginPromoterModel from '../../components/Promoter/withoutlogin-promoter-model/withoutlogin-promoter-model';
import HostLoginSuccess from '../../components/Promoter/host-login-success/host-login-success';
import HostNotCreated from '../../components/Promoter/host-not-created/host-not-created';
import { getSubdomainUrl } from '../../hooks/use-subdomain';
import {
  compareTzDates,
  formatDatetimeWl,
  formatDateToOwnFormatWl,
  formatEventTimeZone,
  numberToCurrency,
  setDateToTimeZone,
  convertTextToHyphenated,
  isUUID,
  isNumeric,
} from '../../helpers/functions';
import { SuperAffiliateContext } from '../../providers/super-affiliate';
import NotFound from '../not-found/not-found';
import { useAppContext } from '../../providers/cookie-policy';
import PresaleLiveModel from '../../components/presale-live-model/presale-live-model';
import apiManager from '../../helpers/apiManager';
import WithoutloginFollowHostModel from '../../components/withoutlogin-follow-host-model/withoutlogin-follow-host-model';
import { ENTITY_ACTIONS } from '../../helpers/enums';
import ExternalEmailDialog from './external-email-dialog/external-email-dialog';

const StyledPhotosWrapper = styled(Pane)`
  display: flex;
  flex-wrap: unset !important;
  -ms-flex-wrap: unset !important;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.lg}px) {
    overflow: unset;
  }
`;

const MapContainer = ({ longitude, latitude, venueName }) => {
  return (
    <Map
      streetViewControl
      google={window.google}
      zoom={14}
      initialCenter={{
        lat: latitude,
        lng: longitude,
      }}
    >
      <Marker name={venueName} position={{ lat: latitude, lng: longitude }} />
    </Map>
  );
};

const EventLocationMap = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapContainer);

const SocialShareIconContainer = styled(Text)`
  color: var(--gray-lightest-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--gray-light-color);
  }
`;

export const EVENT_DETAILS_QUERY = gql`
  query EventDetailsQuery($id: Int) {
    allEvents(
      where: { eventReferenceNumber: { eq: $id }, isActive: { eq: true } }
    ) {
      items {
        externalTicketSellerLinks
        isPromoterIdIncluded
        isFollowHostIncluded
        isEventLikedByUser
        eventReferenceNumber
        EventId: eventId
        coverImageThumbnailUrl
        coverImageUrl
        EventTitle: eventTitle
        EventDescription: eventDescription
        TicketAdminBase: ticketAdminBase
        TicketAdminPercentage: ticketAdminPercentage
        enableWaitlist
        _waitlistStartDate: waitlistStartDate
        waitlistStartDate: _waitlistStartDate
        _waitlistEndDate: waitlistEndDate
        waitlistEndDate: _waitlistEndDate

        VenueTitle: venueTitle
        EventDateFrom: _eventDateFrom
        _EventDateFrom: eventDateFrom
        EventDateTo: _eventDateTo
        _EventDateTo: eventDateTo
        OptionAge: optionAge
        OptionLastEntry: optionLastEntry
        OptionDressCode: optionDressCode
        OptionIdRequirement: optionIdRequirement
        HatAccepted: hatAccepted
        CompanyId: companyId
        EventType: eventType
        ShoeType: shoeType
        optionAgeDescription
        optionIdRequirementDescription
        optionDressCodeDescription
        shoeTypeDescription
        hatAcceptedDescription
        eventDressCodeTerms {
          eventId
          fieldTitle
          fieldDesctiption
          id
        }
        PromoterApprovalType: promoterApprovalType
        AgentPromotionDefaultCommissionPercentage: agentPromotionDefaultCommissionPercentage
        Status: status
        isHiddenVenue
        EventDates: eventDates(order: { eventStartDate: ASC }) {
          _lastEntryTime: lastEntryTime
          lastEntryTime: _lastEntryTime
          EventDateId: eventDateId
          _EventStartDate: eventStartDate
          EventStartDate: _eventStartDate
          EventEndDate: _eventEndDate
          _EventEndDate: eventEndDate
          startSaleDate: _startSaleDate
          _startSaleDate: startSaleDate
          endSaleDate: _endSaleDate
          _endSaleDate: endSaleDate
          _preSaleStartDate: preSaleStartDate
          preSaleStartDate: _preSaleStartDate
          _preSaleEndDate: preSaleEndDate
          preSaleEndDate: _preSaleEndDate
          eventDateName
        }

        Venue: venue {
          VenueTitle: venueTitle
          VenueId: venueId
          AddressLine1: addressLine1
          longitude
          city
          latitude
          timeZoneName
          Country: country {
            ISO2: iso2
            CountryId: countryId
            timezone
          }
        }

        CurrencyDetail: currency {
          CurrencyId: currencyId
          CurrencyShortcode: currencyShortcode
          CurrencySymbol: currencySymbol
        }

        EventGenres: eventGenres {
          EventGenreType: eventGenreType {
            EventGenreTypeId: eventGenreTypeId
            EventGenreTypeDescription: eventGenreTypeDescription
            EventGenreTypeShortcode: eventGenreTypeShortcode
          }
        }

        Products: products(
          order: { productPrice: ASC, isSoldOut: ASC }
          where: { isExcludeFromWebsite: { eq: false } }
        ) {
          ProductId: productId
          StartSaleDate: _startSaleDate
          _StartSaleDate: startSaleDate
          EndSaleDate: _endSaleDate
          _EndSaleDate: endSaleDate
          EventDateId: eventDateId
          ProductTitle: productTitle
          ProductDescription: productDescription
          ProductPrice: productPrice
          IsSoldOut: isSoldOut
          AdminFee: adminFee
          MaximumInterestQuantity: maximumInterestQuantity
          MinimumInterestQuantity: minimumInterestQuantity
          PreSaleStartDate: _preSaleStartDate
          _PreSaleStartDate: preSaleStartDate
          PreSaleEndDate: _preSaleEndDate
          _PreSaleEndDate: preSaleEndDate
          PreSaleEnabled: preSaleEnabled
        }

        Company: company {
          CompanyId: companyId
          CompanyName: companyName
          Description: description
          SocialFeedFacebookLink: socialFeedFacebookLink
          SocialFeedInstagramLink: socialFeedInstagramLink
          SocialFeedTwitterLink: socialFeedTwitterLink
          PublicEmail: publicEmail
          Website: website
        }

        EventTypes: eventTypeNavigation {
          EventTypeShortcode: eventTypeShortcode
          EventTypeDescription: eventTypeDescription
          EventTypeId: eventTypeId
        }

        EventMedia: eventMedia {
          MediaId: mediaId
          mediaUrl: mediaUrl
        }

        SuperCompanyId: superCompanyId
      }
    }
  }
`;

const initialReducerState = {
  currentEvent: null,
  currentAction: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENTITY_ACTIONS.EXTERNAL_EVENT_DIALOG:
      return { currentEvent: payload, currentAction: type };

    case ENTITY_ACTIONS.EXTERNAL_EVENT_DIALOG_CLOSE:
      return initialReducerState;

    default:
      throw new Error('Invalid action for event categories');
  }
};

const EventDetails = () => {
  const authUser = useReactiveVar(authUserVar);
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const { isCookiesAccepted } = useAppContext();
  const [photoLightboxIndex, setPhotoLightboxIndex] = React.useState(0);
  const [isPhotoLightboxOpen, setIsPhotoLightboxOpen] = React.useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false);
  // const [isBuyingTicket, setIsBuyingTicket] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false);
  const [isFollow, setIsFollow] = React.useState(false);
  const [loader, setLoader] = React.useState();

  const [{ currentEvent, currentAction }, dispatch] = React.useReducer(
    reducer,
    initialReducerState
  );

  const closeActionDialog = () => {
    dispatch({ type: ENTITY_ACTIONS.EXTERNAL_EVENT_DIALOG_CLOSE });
  };

  React.useEffect(() => {
    const handleScrolled = () => {
      if (window.scrollY >= 700) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScrolled);

    // return window.removeEventListener('scroll', handleScrolled);
  });

  const isAuth = useReactiveVar(isAuthVar);

  const history = useHistory();

  const descriptionRef = React.useRef();
  const dateTimeRef = React.useRef();
  const photosRef = React.useRef();
  const locationRef = React.useRef();
  const aboutHostRef = React.useRef();
  const categoryRef = React.useRef();
  const genresRef = React.useRef();

  const { id, evnetname } = useParams();

  const isLaptopMd = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.xxl}px)`,
  });

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.lg}px)`,
  });

  const isTabletMd = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.sm}px)`,
  });

  const {
    error,
    data: eventData,
    loading,
    refetch,
  } = useQuery(EVENT_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    skip: !isNumeric(id),
    // onError: (error) => {
    //   toaster.danger(getErrorMessage(error), {
    //     id: 'fetch-event-details-error',
    //   });
    // },
    variables: { id: Number(id) },
  });

  const data = get(eventData, 'allEvents.items[0]', false);

  React.useEffect(() => {
    if (data && evnetname !== convertTextToHyphenated(data?.EventTitle)) {
      history.replace(
        `/events/${id}/${convertTextToHyphenated(data?.EventTitle)}`
      );
    }
    if (isUUID(id) && evnetname && ['presale'].includes(evnetname)) {
      history.push(`/events/${id}?presale=presale`);
    } else if (isUUID(id)) {
      history.push(`/events/${id}`);
    }
  }, [id, data]);

  const { mutateAsync: followedHost } = useMutation(
    apiManager.FollowTheHostAsync
  );

  if (error) {
    getErrorMessage(error);
    return <EventDetailsLoader />;
  }

  if (loading) {
    return <EventDetailsLoader />;
  }

  if (get(eventData, 'allEvents.items.length', 0) <= 0) {
    return <NotFound />;
  }

  if (
    data === null ||
    ['EXPIRED', 'COMPLETED', 'PAST'].includes(data?.Status)
  ) {
    return <EventExpired eventInfo={data} />;
  }

  if (
    data === null ||
    ['UNPUBLISHED', 'DRAFT', 'SCHEDULED'].includes(data?.Status)
  ) {
    return <NotFound />;
  }

  if (eventData && eventData?.Event?.length <= 0) {
    return <NotFound />;
  }

  if (
    data?.SuperCompanyId?.toUpperCase() !==
    superAffiliate.superAffiliateUserId.toUpperCase()
  ) {
    return <NotFound />;
  }

  const {
    EventTitle,
    TicketAdminBase,
    EventDates,
    Venue,
    EventDescription,
    EventMedia,
    CurrencyDetail,
    EventId,
    Products,
    Company,
    EventTypes,
    EventGenres,
    enableWaitlist,
    waitlistStartDate,
    waitlistEndDate,
    CompanyId,
    PromoterApprovalType,
    AgentPromotionDefaultCommissionPercentage,
    coverImageThumbnailUrl,
    isPromoterIdIncluded,
    isFollowHostIncluded,
    externalTicketSellerLinks,
  } = data;

  const followhost = async (payload) => {
    try {
      setLoader(true);

      const { data, message } = await followedHost({
        followedByUserId: authUser?.id,
        hostId: CompanyId,
        notificationTypeId: NOTIFICATION_TYPE?.EVENTTICKETSALE,
        isFollowing: payload,
      });

      if (data) {
        toaster.success(
          message || 'Thanks for showing interest to promote the events'
        );
        setLoader(false);
        refetch();
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  // check if waitlist is enabled
  const waitlistEnabled =
    enableWaitlist &&
    !compareTzDates(
      setDateToTimeZone(waitlistStartDate, true),
      Venue?.Country?.ISO2,
      Venue?.timeZoneName
    ) &&
    compareTzDates(
      setDateToTimeZone(waitlistEndDate, true),
      Venue?.Country?.ISO2,
      Venue?.timeZoneName
    );

  // check if all tickets are sold out
  const ticketsSoldOut = Products.every(({ IsSoldOut }) => !!IsSoldOut);

  const EVENT_SECTIONS = [
    { title: 'Description', path: descriptionRef },
    { title: 'Date and Time', path: dateTimeRef },
    ...(EventMedia?.length > 0 ? [{ title: 'Photos', path: photosRef }] : []),
    { title: 'Location', path: locationRef },
    { title: 'About the host', path: aboutHostRef },
    { title: 'Category', path: categoryRef },
    { title: 'Genres', path: genresRef },
  ];

  const handleClick = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderActionButton = (footerActionBtn) => {
    let minStartSaleDate,
      maxEndSaleDate,
      hasSaleNotStarted,
      hasSaleEnded,
      hasTicketSaleToday,
      remainingTickets,
      minNextTicketStartSaleDate;

    if (Products?.length) {
      // minimum ticket start sale date
      minStartSaleDate = Math.min(
        ...EventDates?.filter((date) => !!date.startSaleDate)?.map(
          (date) => new Date(date?.startSaleDate)
        ),
        ...Products?.filter((date) => !!date.StartSaleDate)?.map(
          (ticket) => new Date(ticket.StartSaleDate)
        )
      );

      // maximum ticket end sale date
      maxEndSaleDate = Math.max(
        ...Products?.filter((date) => !!date.EndSaleDate)?.map(
          (ticket) => new Date(ticket.EndSaleDate)
        )
      );

      // check if ticket sale has  started at all
      hasSaleNotStarted = compareTzDates(
        setDateToTimeZone(minStartSaleDate, true),
        Venue?.Country?.ISO2,
        Venue?.timeZoneName
      );

      // check if ticket sale has ended for all
      hasSaleEnded = !compareTzDates(
        setDateToTimeZone(maxEndSaleDate, true),
        Venue?.Country?.ISO2,
        Venue?.timeZoneName
      );

      // check if there are any tickets on sale today
      hasTicketSaleToday = Products.some(
        (ticket) =>
          !compareTzDates(
            setDateToTimeZone(ticket.StartSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          ) &&
          compareTzDates(
            setDateToTimeZone(ticket.EndSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          )
      );

      // check for the remaining tickets
      remainingTickets = Products.filter((ticket) =>
        compareTzDates(
          setDateToTimeZone(ticket.StartSaleDate, true),
          Venue?.Country?.ISO2,
          Venue?.timeZoneName
        )
      );

      // check the next available ticket start sale date
      minNextTicketStartSaleDate = Math.min(
        ...remainingTickets
          ?.filter((ticket) => !!ticket.StartSaleDate)
          ?.map((ticket) => new Date(ticket.StartSaleDate))
      );
    }

    // if waitlist is enabled
    return waitlistEnabled ? (
      <Link
        to={`/events/${id}/${convertTextToHyphenated(
          EventTitle
        )}/register-interest`}
      >
        <Btn
          fontSize="1em"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          // onClick={handleClick}
        >
          REGISTER INTEREST
        </Btn>
      </Link>
    ) : externalTicketSellerLinks ? (
      <Btn
        fontSize="1em"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() =>
          dispatch({
            type: ENTITY_ACTIONS.EXTERNAL_EVENT_DIALOG,
            payload: { CurrencyDetail: CurrencyDetail },
          })
        }
      >
        BUY TICKETS
      </Btn>
    ) : // if there are no tickets
    !Products.length ? (
      <Pane
        fontSize="1em"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        disabled="true"
        padding="15px"
        border="1px solid var(--text-white-color) !important"
        color="var(--primary-default-color) !important"
        textAlign="center"
        backgroundColor="rgba(67, 90, 111, 0.06) !important"
        fontFamily="ProximaNova, sans-serif"
      >
        NO TICKETS AVAILABLE
      </Pane>
    ) : // if all tickets are sold out
    ticketsSoldOut ? (
      <Btn
        fontSize="1em"
        width={isTabletMd ? '100%' : '120px'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        disabled="true"
        borderColor={footerActionBtn ? 'var(--primary-default-color)' : null}
        disabledcolor={footerActionBtn ? 'var(--primary-default-color)' : null}
      >
        SOLD OUT
      </Btn>
    ) : // if tickets are not yet on sale
    hasSaleNotStarted ? (
      <Btn
        fontSize={isTabletMd ? '1em' : '12px'}
        width={isTabletMd ? '100%' : '170px'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        disabled="true"
        padding="30px"
        borderColor={footerActionBtn ? 'var(--primary-default-color)' : null}
        disabledcolor={footerActionBtn ? '#fff' : null}
      >
        TICKET SALE STARTS FROM <br />
        {formatDateToOwnFormatWl(minStartSaleDate, "d LLL yyyy',' T")}
      </Btn>
    ) : //if all tickets are no longer on sale
    hasSaleEnded ? (
      <Pane
        fontSize="1em"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        disabled="true"
        padding="15px"
        border="1px solid var(--text-white-color) !important"
        color="var(--primary-default-color) !important"
        textAlign="center"
        backgroundColor="rgba(67, 90, 111, 0.06) !important"
        fontFamily="ProximaNova, sans-serif"
      >
        OFF SALE
      </Pane>
    ) : hasTicketSaleToday ? (
      <Link to={`/events/${id}/${convertTextToHyphenated(EventTitle)}/buy`}>
        <Btn
          fontSize="1em"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          BUY TICKETS
        </Btn>
      </Link>
    ) : remainingTickets.length ? (
      <Btn
        fontSize="1em"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        disabled="true"
        padding="30px"
      >
        NEXT TICKET SALE STARTS
        <br />
        {formatDateToOwnFormatWl(minNextTicketStartSaleDate, "d LLL yyyy',' T")}
      </Btn>
    ) : null;
  };

  const minStartSaleDate = EventDates?.reduce((minDate, event) => {
    const startSaleDate = new Date(event.startSaleDate);
    return minDate === null || startSaleDate < minDate
      ? startSaleDate
      : minDate;
  }, null);

  const PreSaleStartDate = Products.map(
    ({ PreSaleEnabled, PreSaleStartDate, PreSaleEndDate }) => {
      if (PreSaleEnabled && PreSaleStartDate && PreSaleEndDate) {
        const hasSaleStarted = !compareTzDates(
          setDateToTimeZone(PreSaleStartDate, true),
          Venue?.Country?.ISO2,
          Venue?.timeZoneName
        );
        const hasSaleEnded = compareTzDates(
          setDateToTimeZone(PreSaleEndDate, true),
          Venue?.Country?.ISO2,
          Venue?.timeZoneName
        );

        return PreSaleEnabled && hasSaleStarted && hasSaleEnded;
      } else {
        return false;
      }
    }
  );

  const preSaleStarted = PreSaleStartDate.some((item) => item === true);

  const renderTicketPrice = (showFrom) => {
    const currency = CurrencyDetail.CurrencySymbol;

    let hasTicketSaleToday;

    if (Products?.length) {
      // check if there are any tickets on sale today
      hasTicketSaleToday = Products.some(
        (ticket) =>
          !compareTzDates(
            setDateToTimeZone(ticket.StartSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          ) &&
          compareTzDates(
            setDateToTimeZone(ticket.EndSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          )
      );
    }

    const _Products = !!hasTicketSaleToday
      ? Products?.filter(({ StartSaleDate, EndSaleDate }) => {
          const hasSaleStarted = !compareTzDates(
            setDateToTimeZone(StartSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          );
          const hasSaleEnded = compareTzDates(
            setDateToTimeZone(EndSaleDate, true),
            Venue?.Country?.ISO2,
            Venue?.timeZoneName
          );

          return hasSaleStarted && hasSaleEnded;
        })
      : Products;

    if (_Products.length === 1) {
      return numberToCurrency(
        currencyJs(_Products[0]?.ProductPrice).add(_Products[0]?.AdminFee)
          .value,
        currency
      );
    }

    const minPrice = Math.min(
      ..._Products.map((ticket) => ticket.ProductPrice)
    );
    const maxPrice = Math.max(
      ..._Products.map((ticket) => ticket.ProductPrice)
    );

    // const minStartDate = tickets.sort(
    //   (a, b) => a.startSaleDate - b.startSaleDate
    // );

    const minBookingFee = Math.min(
      ..._Products.map((ticket) => ticket.AdminFee)
    );
    const maxBookingFee = Math.max(
      ..._Products.map((ticket) => ticket.AdminFee)
    );

    const minAdminFee = currencyJs(minBookingFee).value;
    const maxAdminFee = currencyJs(maxBookingFee).value;

    return (
      <>
        {showFrom ? (
          <Text
            fontSize="inherit"
            fontWeight="inherit"
            color="var(--text-white-color)"
          >
            FROM:{' '}
            <Text
              fontSize="inherit"
              fontWeight="inherit"
              color="var(--text-white-color)"
              wordBreak="break-all"
            >
              {numberToCurrency(
                currencyJs(minPrice).add(minAdminFee).value,
                currency
              )}{' '}
            </Text>
          </Text>
        ) : (
          <Text fontSize="inherit" fontWeight="inherit" color="inherit">
            {numberToCurrency(
              currencyJs(minPrice).add(minAdminFee).value,
              currency
            )}{' '}
            -{' '}
            {numberToCurrency(
              currencyJs(maxPrice).add(maxAdminFee).value,
              currency
            )}
          </Text>
        )}
      </>
    );
  };

  const paddingValue = isLaptop
    ? majorScale(0)
    : isLaptop && !isCookiesAccepted
    ? majorScale(0)
    : !isLaptop && !!isCookiesAccepted
    ? majorScale(1)
    : majorScale(7);

  const finalPadding =
    isLaptop && !isCookiesAccepted ? majorScale(6) : paddingValue;

  // maximum ticket end sale date
  const maxEndSaleDate = Math.max(
    ...Products?.filter((date) => !!date.EndSaleDate)?.map(
      (ticket) => new Date(ticket.EndSaleDate)
    )
  );

  // check if ticket sale has ended for all
  const hasSaleEnded = !compareTzDates(
    setDateToTimeZone(maxEndSaleDate, true),
    Venue?.Country?.ISO2,
    Venue?.timeZoneName
  );

  return (
    <>
      <ReactSEOMetaTags
        website={{
          title: EventTitle,
          description: `${EventTitle} by ${
            Company?.CompanyName
          } - ${formatDatetimeWl(EventDates[0]?.EventStartDate)} at ${
            Venue?.VenueTitle
          }. Buy tickets and find information on ${
            superAffiliate.businessName
          }.`,
        }}
      />

      <EventDetailsHeader
        event={data}
        setIsShown={setIsShown}
        eventId={id}
        hasSaleEnded={hasSaleEnded}
        externalTicketSellerLinks={externalTicketSellerLinks}
        ticketsSoldOut={ticketsSoldOut}
      />
      {isLaptop && (
        <Pane
          is="section"
          fontSize="1rem"
          borderTop="1px solid var(--black-lightest-color)"
          borderBottom="1px solid var(--black-lightest-color)"
        >
          <Container maxWidth="100%" position="relative">
            <UnorderedList
              display="flex"
              marginLeft={0}
              color="var(--black-lighter-color)"
            >
              {EVENT_SECTIONS.map(({ title, path }, i) => (
                <ListItem
                  key={i}
                  marginY={0}
                  paddingY={minorScale(5)}
                  marginRight={majorScale(5)}
                  fontSize="1em"
                  cursor="pointer"
                  color="var(--black-lighest)"
                  onClick={() => handleClick(path)}
                  height="65px"
                >
                  {title}
                </ListItem>
              ))}
            </UnorderedList>

            <Pane
              width={isLaptopMd ? 420 : 300}
              position="absolute"
              top="0"
              right="20px"
              backgroundColor="#fff"
              elevation={2}
            >
              <Paragraph
                paddingX={minorScale(8)}
                paddingY={minorScale(5)}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                color="var(--black-light-color)"
                fontSize="1em"
                lineHeight={1.1}
                borderBottom="1px solid var(--black-lightest-color)"
                height="66px"
              >
                {waitlistEnabled ||
                !Products.length ||
                externalTicketSellerLinks ? (
                  <Text>&nbsp;</Text>
                ) : (
                  <>
                    <Text
                      fontSize="inherit"
                      lineHeight="inherit"
                      display="inline-block"
                    >
                      Price
                    </Text>
                    <Text
                      fontSize="1em"
                      fontWeight="bold"
                      color="inherit"
                      display="inline-block"
                      textAlign="right"
                    >
                      {renderTicketPrice()}
                    </Text>
                  </>
                )}
              </Paragraph>
              <Pane
                paddingX={minorScale(8)}
                paddingY={minorScale(5)}
                borderBottom="1px solid rgba(28, 28, 28, 0.15)"
              >
                {renderActionButton()}
                {waitlistEnabled && (
                  <>
                    <Countdown
                      date={minStartSaleDate}
                      renderer={CountdownRenderer}
                    />
                    <Btn
                      fontSize="1em"
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      disabled="true"
                      padding="30px"
                    >
                      TICKET SALE STARTS FROM <br />
                      {formatDateToOwnFormatWl(
                        minStartSaleDate,
                        "d LLL yyyy',' T"
                      )}
                    </Btn>
                  </>
                )}
              </Pane>

              <Pane
                paddingX={minorScale(8)}
                paddingY={minorScale(5)}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* <ShareIcon title="Share event" /> */}

                {/* via, hashtags */}
                <TwitterShareButton
                  related={['ticketsir']}
                  url={window.location.href}
                  title={`Can't wait to go to ${EventTitle}! To join me, grab your tickets`}
                >
                  <SocialShareIconContainer>
                    <TwitterIcon cursor="pointer" />
                  </SocialShareIconContainer>
                </TwitterShareButton>

                <FacebookShareButton
                  url={window.location.href}
                  quote={`Can't wait to go to ${EventTitle}! To join me, grab your tickets`}
                  hashtag="#ticketsir"
                >
                  <SocialShareIconContainer>
                    <FacebookIcon cursor="pointer" />
                  </SocialShareIconContainer>
                </FacebookShareButton>
                <FavoriteEvent eventItem={data} onSuccess={refetch} />
              </Pane>
            </Pane>
          </Container>
        </Pane>
      )}

      <Container maxWidth="100%" paddingY={minorScale(0)}>
        <Pane
          is="section"
          maxWidth={720}
          fontSize="1rem"
          color="var(--black-light-color)"
          ref={descriptionRef}
        >
          <Heading
            marginTop={isTablet ? minorScale(10) : majorScale(4)}
            marginBottom={minorScale(4)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Description
          </Heading>

          <Paragraph
            // maxWidth={!isLaptop ? 650 : 'unset'}
            color="inherit"
            fontSize="1em"
            dangerouslySetInnerHTML={{
              __html: EventDescription || '',
            }}
          />
          {Products.length > 0 && !isEmpty(EventDates) && (
            <>
              <Heading
                marginTop={isTablet ? minorScale(10) : majorScale(4)}
                marginBottom={minorScale(4)}
                color="var(--text-default-color)"
                fontSize="1.5em"
                fontWeight={700}
                ref={dateTimeRef}
              >
                Date and Time
              </Heading>

              <Pane
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                flexWrap="wrap"
                width="100%"
                gap={minorScale(4)}
                color="inherit"
              >
                {EventDates?.filter(({ EventDateId: id }) =>
                  Products.some(({ EventDateId }) => id === EventDateId)
                )?.map((eventDate, i) => {
                  const hasEnded = compareTzDates(
                    setDateToTimeZone(eventDate.EventEndDate, true),
                    Venue?.Country?.ISO2,
                    Venue?.timeZoneName
                  );

                  return waitlistEnabled ? (
                    // <Link to={`/events/${id}/register-interest`}>
                    <Text
                      key={i}
                      lineHeight={1.5}
                      paddingY={majorScale(2)}
                      paddingX={majorScale(2)}
                      minWidth={200}
                      fontSize="1em"
                      display="inline-block"
                      backgroundColor={hasEnded ? '#FFF3EC' : '#efefef'}
                      borderRadius={majorScale(1)}
                      color="inherit"
                      width="330px"
                      // cursor="pointer"
                      onClick={handleClick}
                    >
                      <Pane display="flex" justifyContent="space-between">
                        <Pane
                          borderRight="1px solid #CFD8DC"
                          textAlign="center"
                          paddingRight={majorScale(1)}
                        >
                          <Heading fontSize="2em" fontWeight="400">
                            {formatDateToOwnFormatWl(
                              eventDate.EventStartDate,
                              'dd'
                            )}
                          </Heading>
                          <Heading fontWeight="400">
                            {formatDateToOwnFormatWl(
                              eventDate.EventStartDate,
                              'LLL'
                            )}
                          </Heading>
                        </Pane>

                        <Pane paddingLeft={majorScale(1)}>
                          <Pane display="flex" alignItems="center">
                            <Paragraph
                              marginBottom={minorScale(1)}
                              marginRight={majorScale(2)}
                              display="flex"
                              alignItems="center"
                              fontSize="0.875em"
                              width="72px"
                              lineHeight="16px"
                            >
                              Start Date
                            </Paragraph>

                            <Paragraph
                              marginBottom={minorScale(1)}
                              fontSize="0.875em"
                              lineHeight="16px"
                            >
                              {formatDateToOwnFormatWl(
                                eventDate.EventStartDate,
                                "dd LLL yyyy',' T"
                              )}{' '}
                              HRS
                            </Paragraph>
                          </Pane>

                          <Pane display="flex" alignItems="center">
                            <Paragraph
                              marginBottom={minorScale(1)}
                              marginRight={majorScale(2)}
                              display="flex"
                              alignItems="center"
                              fontSize="0.875em"
                              width="72px"
                              lineHeight="16px"
                            >
                              End Date
                            </Paragraph>

                            <Paragraph
                              marginBottom={minorScale(1)}
                              fontSize="0.875em"
                              lineHeight="16px"
                            >
                              {formatDateToOwnFormatWl(
                                eventDate.EventEndDate,
                                "dd LLL yyyy',' T"
                              )}{' '}
                              HRS
                            </Paragraph>
                          </Pane>
                          {EventDates[0]?.lastEntryTime && (
                            <Pane display="flex" alignItems="center">
                              <Paragraph
                                marginBottom={minorScale(1)}
                                marginRight={majorScale(2)}
                                display="flex"
                                alignItems="center"
                                fontSize="0.875em"
                                width="72px"
                                lineHeight="16px"
                              >
                                Gate closes
                              </Paragraph>

                              <Paragraph
                                marginBottom={minorScale(1)}
                                fontSize="0.875em"
                                lineHeight="16px"
                              >
                                {formatDateToOwnFormatWl(
                                  eventDate.lastEntryTime,
                                  "dd LLL yyyy',' T"
                                )}{' '}
                                HRS
                              </Paragraph>
                            </Pane>
                          )}
                        </Pane>
                      </Pane>
                    </Text>
                  ) : (
                    // </Link>
                    // <Link
                    //   key={i}
                    //   to={{
                    //     pathname: `/events/${id}/buy`,
                    //     state: {
                    //       eventInfo: data,
                    //       selectedDate: eventDate,
                    //     },
                    //   }}
                    // >
                    <Text
                      key={i}
                      lineHeight={1.5}
                      paddingY={majorScale(2)}
                      paddingX={majorScale(2)}
                      minWidth={200}
                      fontSize="1em"
                      display="inline-block"
                      backgroundColor={hasEnded ? '#FFF3EC' : '#efefef'}
                      borderRadius={majorScale(1)}
                      color="inherit"
                      width="330px"
                      // cursor="pointer"
                    >
                      <Pane display="flex" justifyContent="space-between">
                        <Pane
                          borderRight="1px solid #CFD8DC"
                          textAlign="center"
                          paddingRight={majorScale(1)}
                        >
                          <Heading fontSize="2em" fontWeight="400">
                            {formatDateToOwnFormatWl(
                              eventDate.EventStartDate,
                              'dd'
                            )}
                          </Heading>
                          <Heading fontWeight="400">
                            {formatDateToOwnFormatWl(
                              eventDate.EventStartDate,
                              'LLL'
                            )}
                          </Heading>
                        </Pane>

                        <Pane paddingLeft={majorScale(1)}>
                          <Pane display="flex" alignItems="center">
                            <Paragraph
                              marginBottom={minorScale(1)}
                              marginRight={majorScale(2)}
                              display="flex"
                              alignItems="center"
                              fontSize="0.875em"
                              width="72px"
                              lineHeight="16px"
                            >
                              Start Date
                            </Paragraph>

                            <Paragraph
                              marginBottom={minorScale(1)}
                              fontSize="0.875em"
                              lineHeight="16px"
                            >
                              {formatDateToOwnFormatWl(
                                eventDate.EventStartDate,
                                "dd LLL yyyy',' T"
                              )}{' '}
                              HRS
                            </Paragraph>
                          </Pane>

                          <Pane display="flex" alignItems="center">
                            <Paragraph
                              marginBottom={minorScale(1)}
                              marginRight={majorScale(2)}
                              display="flex"
                              alignItems="center"
                              fontSize="0.875em"
                              width="72px"
                              lineHeight="16px"
                            >
                              End Date
                            </Paragraph>

                            <Paragraph
                              marginBottom={minorScale(1)}
                              fontSize="0.875em"
                              lineHeight="16px"
                            >
                              {formatDateToOwnFormatWl(
                                eventDate.EventEndDate,
                                "dd LLL yyyy',' T"
                              )}{' '}
                              HRS
                            </Paragraph>
                          </Pane>
                          {EventDates[0]?.lastEntryTime && (
                            <Pane display="flex" alignItems="center">
                              <Paragraph
                                marginBottom={minorScale(1)}
                                marginRight={majorScale(2)}
                                display="flex"
                                alignItems="center"
                                fontSize="0.875em"
                                width="72px"
                                lineHeight="16px"
                              >
                                Gate closes
                              </Paragraph>

                              <Paragraph
                                marginBottom={minorScale(1)}
                                fontSize="0.875em"
                                lineHeight="16px"
                              >
                                {formatDateToOwnFormatWl(
                                  eventDate.lastEntryTime,
                                  "dd LLL yyyy',' T"
                                )}{' '}
                                HRS
                              </Paragraph>
                            </Pane>
                          )}
                        </Pane>
                      </Pane>
                    </Text>
                    // </Link>
                  );
                })}
              </Pane>
            </>
          )}
          {EventMedia.length > 0 && (
            <>
              <Heading
                marginTop={isTablet ? minorScale(10) : majorScale(4)}
                marginBottom={minorScale(4)}
                color="var(--text-default-color)"
                fontSize="1.5em"
                fontWeight={700}
                ref={photosRef}
              >
                Photos
              </Heading>

              {EventMedia.length > 0 && (
                <PhotoLightbox
                  photos={EventMedia.map((media) => media.mediaUrl)}
                  initialPhotoIndex={photoLightboxIndex}
                  isLightboxOpen={isPhotoLightboxOpen}
                  onCloseComplete={() => setIsPhotoLightboxOpen(false)}
                />
              )}

              <StyledPhotosWrapper rowGap={minorScale(7)} className="row">
                <Pane display="flex" marginRight="mr-n3">
                  {EventMedia.length > 0 ? (
                    EventMedia.map((media, i) => (
                      <Pane
                        key={media.MediaId}
                        width={240}
                        overflow="hidden"
                        marginRight={isTabletMd ? minorScale(7) : minorScale(4)}
                        cursor="pointer"
                        onClick={() => {
                          setPhotoLightboxIndex(i);
                          setIsPhotoLightboxOpen(true);
                        }}
                        // className="mr-3 mr-md-4"
                      >
                        <img
                          src={media.mediaUrl}
                          alt={EventTitle}
                          style={{
                            width: '240px',
                            height: '140px',
                            borderRadius: '5px',
                          }}
                        />
                      </Pane>
                    ))
                  ) : (
                    <Paragraph
                      fontStyle="italic"
                      textAlign="center"
                      fontSize="1em"
                    >
                      No additional images were uploaded for this event.
                    </Paragraph>
                  )}
                </Pane>
              </StyledPhotosWrapper>
            </>
          )}

          {!data?.isHiddenVenue && (
            <>
              <Heading
                marginTop={isTablet ? minorScale(10) : majorScale(4)}
                marginBottom={minorScale(4)}
                color="var(--text-default-color)"
                fontSize="1.5em"
                fontWeight={700}
                ref={locationRef}
              >
                Location
              </Heading>

              <Paragraph
                marginBottom={minorScale(3)}
                color="inherit"
                fontWeight={700}
                fontSize="1em"
              >
                {Venue?.VenueTitle}
              </Paragraph>

              <Paragraph
                marginBottom={minorScale(3)}
                color="inherit"
                fontSize="1em"
              >
                {Venue?.AddressLine1}
              </Paragraph>

              <Pane
                width={isLaptop ? 960 : '100%'}
                height={isTablet ? 420 : 360}
                position="relative"
              >
                <EventLocationMap
                  longitude={Venue?.longitude}
                  latitude={Venue?.latitude}
                  venueName={Venue?.VenueTitle}
                />
              </Pane>
            </>
          )}
          <Pane
            display="flex"
            alignItems="baseline"
            columnGap={majorScale(2)}
            ref={aboutHostRef}
            marginTop={isTablet ? minorScale(10) : majorScale(4)}
            marginBottom={minorScale(4)}
          >
            <Heading
              color="var(--text-default-color)"
              fontSize="1.5em"
              fontWeight={700}
            >
              About the host
            </Heading>
            <a
              href={`https://${getSubdomainUrl(data?.Company?.CompanyName)}`}
              target="_blank"
            >
              <Text fontSize="1.25em" color="var(--primary-default-color)">
                {data?.Company?.CompanyName}
              </Text>
            </a>
          </Pane>

          <Paragraph
            marginBottom={minorScale(2)}
            color="inherit"
            fontSize="1em"
            wordBreak="break-word"
          >
            {Company.Description}
          </Paragraph>

          <Pane display="flex" justifyContent="space-between">
            <Btn
              type="button"
              fontSize="1em"
              paddingX={0}
              columnGap={minorScale(3)}
              look="primary-minimal"
              onClick={() => {
                if (isAuth) {
                  history.push('/contact-us');
                } else {
                  setIsAuthModalOpen(true);
                }
              }}
            >
              Contact Host <ArrowRightIcon />
            </Btn>
            {authUser?.hostId?.toLowerCase() !== CompanyId?.toLowerCase() && (
              <Btn
                type="button"
                fontSize="1em"
                columnGap={minorScale(3)}
                onClick={() => {
                  if (isAuth) {
                    followhost(!isFollowHostIncluded);
                  } else {
                    setIsFollow(true);
                  }
                }}
                isLoading={loader}
              >
                {!isAuth
                  ? 'Follow the host'
                  : isFollowHostIncluded
                  ? 'Following the host'
                  : 'Follow the host'}
              </Btn>
            )}
          </Pane>

          {isAuthModalOpen && (
            <AuthModal
              isOpen={isAuthModalOpen}
              callback={() => history.push('/contact-us')}
              closeModal={() => setIsAuthModalOpen(false)}
            />
          )}

          <Heading
            marginTop={isTablet ? minorScale(10) : majorScale(4)}
            marginBottom={minorScale(4)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
            ref={categoryRef}
          >
            Category
          </Heading>

          <UnorderedList
            display="flex"
            rowGap={majorScale(2)}
            columnGap={majorScale(2)}
          >
            <ListItem
              paddingY={majorScale(1)}
              paddingX={majorScale(2)}
              color="inherit"
              fontSize="1em"
              backgroundColor="#EFF9FF"
              borderRadius={majorScale(1)}
            >
              <Link
                to={{
                  pathname: '/search-events',
                  search: `?${queryString.stringify({
                    category: EventTypes.EventTypeId,
                    country: Venue?.Country?.CountryId,
                  })}`,
                  state: {
                    category: EventTypes.EventTypeId,
                    countryId: Venue?.Country?.CountryId,
                  },
                }}
              >
                {EventTypes.EventTypeShortcode}
              </Link>
            </ListItem>
          </UnorderedList>

          <Heading
            marginTop={isTablet ? minorScale(10) : majorScale(4)}
            marginBottom={minorScale(4)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
            ref={genresRef}
          >
            Genres
          </Heading>

          <UnorderedList
            display="flex"
            rowGap={majorScale(2)}
            columnGap={majorScale(2)}
            flexWrap="wrap"
          >
            {EventGenres.map((genre, i) => (
              <ListItem
                key={i}
                paddingY={majorScale(1)}
                paddingX={majorScale(2)}
                color="inherit"
                fontSize="1em"
                backgroundColor="#EFF9FF"
                borderRadius={majorScale(1)}
              >
                {/* <Link
                  to={{
                    pathname: '/search-events',
                    state: {
                      genre: genre.EventGenreType.EventGenreTypeId,
                      countryId: Venue?.Country?.CountryId,
                    },
                  }}
                > */}
                <Text color="var(--primary-default-color)" fontSize="1em">
                  {genre.EventGenreType.EventGenreTypeShortcode}
                </Text>

                {/* </Link> */}
              </ListItem>
            ))}
          </UnorderedList>
        </Pane>
      </Container>

      {(!isLaptop || scrolled) && (
        <>
          <Pane
            borderTop={!isTabletMd && waitlistEnabled ? '1px solid #fff' : ''}
            position="fixed"
            bottom={
              !isCookiesAccepted && isLaptop
                ? '95px'
                : !isCookiesAccepted
                ? '132px'
                : '50px'
            }
            paddingY={!isCookiesAccepted ? minorScale(1) : minorScale(3)}
            width="100%"
            backgroundColor="var(--secondary-dark-color)"
            zIndex={1}
          >
            <Container maxWidth="100%">
              <Pane
                marginLeft="auto"
                display="flex"
                alignItems="center"
                maxWidth={isLaptop ? '300px !important' : '100% !important'}
                justifyContent={isLaptop ? 'flex-end' : 'space-between'}
              >
                {waitlistEnabled && (
                  <Countdown
                    date={minStartSaleDate}
                    renderer={({ days, hours, minutes }) => (
                      <CountdownRenderer
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        isLaptop={isLaptop}
                        color={'#fff'}
                      />
                    )}
                  />
                )}
              </Pane>
            </Container>
          </Pane>
          <Pane
            borderTop="1px solid rgba(34,41,67,1)"
            position="fixed"
            bottom={!isLaptop && !isCookiesAccepted ? '25px' : 0}
            paddingY={minorScale(3)}
            width="100%"
            backgroundColor="var(--secondary-dark-color)"
            zIndex={1}
          >
            <Container
              display="flex"
              alignItems="center"
              maxWidth="100%"
              justifyContent={isLaptop ? 'flex-end' : 'space-between'}
              paddingBottom={finalPadding}
            >
              {!waitlistEnabled && !!Products.length && (
                <Text
                  fontSize="1em"
                  fontWeight="bold"
                  color="var(--text-white-color)"
                >
                  {renderTicketPrice(true)}
                </Text>
              )}

              <Pane
                minWidth={isLaptop ? 300 : 120}
                marginLeft={isLaptop ? 32 : 'auto'}
                display="flex"
                alignItems="center"
              >
                {authUser?.hostId?.toLowerCase() !== CompanyId?.toLowerCase() &&
                !isPromoterIdIncluded &&
                !ticketsSoldOut &&
                !hasSaleEnded &&
                (PromoterApprovalType === APPROVAL_TYPE.AUTOAPPROVE ||
                  PromoterApprovalType === APPROVAL_TYPE.MANUALAPPROVAL) ? (
                  <Btn
                    fontSize="1em"
                    look="primary-outlined"
                    padding={isLaptop ? '10px' : '3px'}
                    marginRight={isLaptop ? '20px' : '10px'}
                    onClick={() => {
                      setIsShown(true);
                    }}
                  >
                    Promote
                  </Btn>
                ) : (
                  ''
                )}
                {renderActionButton(true)}
              </Pane>
            </Container>
          </Pane>
        </>
      )}

      <PresaleLiveModel preSaleStarted={preSaleStarted && waitlistEnabled} />

      {!authUser.hostId && isAuth ? (
        <HostNotCreated
          isShown={isShown}
          setIsShown={setIsShown}
          EventTitle={EventTitle}
          id={id}
          image={data?.coverImageUrl}
          commission={AgentPromotionDefaultCommissionPercentage}
        />
      ) : isAuth && authUser.hostId ? (
        <HostLoginSuccess
          isShown={isShown}
          setIsShown={setIsShown}
          EventTitle={EventTitle}
          id={id}
          image={data?.coverImageUrl}
          commission={AgentPromotionDefaultCommissionPercentage}
          refetch={refetch}
        />
      ) : (
        <WithoutloginPromoterModel
          isShown={isShown}
          setIsShown={setIsShown}
          EventTitle={EventTitle}
          id={id}
          image={data?.coverImageUrl}
          commission={AgentPromotionDefaultCommissionPercentage}
        />
      )}

      {!isAuth && (
        <WithoutloginFollowHostModel
          isShown={isFollow}
          setIsShown={setIsFollow}
          EventTitle={EventTitle}
          id={id}
          image={data?.coverImageUrl}
          commission={AgentPromotionDefaultCommissionPercentage}
          followhost={followhost}
          CompanyId={CompanyId}
          isFollowHostIncluded={isFollowHostIncluded}
          refetch={refetch}
          isFollow={true}
        />
      )}

      {currentAction === ENTITY_ACTIONS.EXTERNAL_EVENT_DIALOG && (
        <ExternalEmailDialog
          currentEvent={currentEvent}
          currentAction={currentAction}
          closeActionDialog={closeActionDialog}
          externalTicketSellerLinks={externalTicketSellerLinks}
          EventId={EventId}
        />
      )}

      {superAffiliate.withoutHeader && (
        <Pane paddingBottom={majorScale(6)}>
          <Footer waitlistEnabled={waitlistEnabled} />
        </Pane>
      )}
    </>
  );
};

const CountdownRenderer = ({ days, hours, minutes, color }) => {
  const isColor = color ? { color: color } : '#474d66';
  return (
    <Pane
      height="100%"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginTop={majorScale(2)}
      gap={majorScale(2)}
    >
      <Pane textAlign="center">
        <Paragraph {...isColor} fontSize="24px">
          {zeroPad(days) || '00'}
        </Paragraph>
        <Paragraph {...isColor}>Days</Paragraph>
      </Pane>
      <Text fontWeight="700" {...isColor}>
        :
      </Text>

      <Pane textAlign="center">
        <Paragraph {...isColor} fontSize="24px">
          {zeroPad(hours) || '00'}
        </Paragraph>
        <Paragraph {...isColor}>Hours</Paragraph>
      </Pane>
      <Text fontWeight="700" {...isColor}>
        :
      </Text>

      <Pane textAlign="center">
        <Paragraph {...isColor} fontSize="24px">
          {zeroPad(minutes) || '00'}
        </Paragraph>
        <Paragraph {...isColor}>Minutes</Paragraph>
      </Pane>
    </Pane>
  );
};
export default EventDetails;
